import { Fragment, useEffect, useState, useRef } from 'react'

import {
	useAnimateInReady,
	useHandleSurveySubmissions,
	useSetAnimationReady,
	useSetNextButtonDisabled,
	useSurveyState,
	useSurveySubmissions
} from '@context/subscription-box/useSurvey'
import { Transition } from '@headlessui/react'

import type { SurveyQuestion } from '@lib/types/subscription-box'

type MultiSelectParams = {
	surveyQuestion: SurveyQuestion
	isRequired?: boolean
	index: number
}
export default function MultiSelect({ surveyQuestion, isRequired, index }: MultiSelectParams) {
	const { currentIndex, translateDirection } = useSurveyState()
	const animateInReady = useAnimateInReady()
	const setAnimateInReady = useSetAnimationReady()
	const handleSurveySubmissions = useHandleSurveySubmissions()
	const setNextButtonDisabled = useSetNextButtonDisabled()
	const surveySubmissions = useSurveySubmissions()

	// Track if user has interacted with this component to prevent
	// overriding their selections with synchronized data
	const userInteractedRef = useRef<boolean>(false)
	// Track previous submission state to avoid unnecessary updates
	const prevSubmissionRef = useRef<string[]>([])

	const [answerOptions, setAnswerOptions] = useState(
		surveyQuestion?.surveyAnswerOptions?.map(({ optionValue, optionLabel, id }) => ({
			optionValue,
			optionLabel,
			active: false,
			id
		}))
	)

	// Handle state change on option click
	const handleValueChange = (optionValue: string) => {
		// Mark that user has interacted with this component
		userInteractedRef.current = true

		setAnswerOptions((prev) => {
			const updated = prev.map(({ optionValue: prevOptionValue, active: prevActive, ...rest }) => ({
				optionValue: prevOptionValue,
				active: optionValue === prevOptionValue ? !prevActive : prevActive,
				...rest
			}))
			return updated
		})
	}

	// Sync with external survey submissions - only runs before user interaction
	useEffect(() => {
		if (userInteractedRef.current) return

		const submission = surveySubmissions.find((sub) => sub.questionId === surveyQuestion.id)
		if (!submission?.response?.length) return

		// Use JSON.stringify for simpler deep comparison
		const responseJSON = JSON.stringify([...submission.response].sort())
		const prevResponseJSON = JSON.stringify([...prevSubmissionRef.current].sort())

		if (responseJSON !== prevResponseJSON) {
			// Update local state to match submission
			setAnswerOptions((prev) =>
				prev.map((option) => ({
					...option,
					active: submission.response.includes(option.optionValue)
				}))
			)

			// Store current submission to prevent future unnecessary updates
			prevSubmissionRef.current = [...submission.response]
		}
	}, [surveySubmissions, surveyQuestion.id])

	// Update survey context when options change
	useEffect(() => {
		if (index === currentIndex) {
			handleSurveySubmissions(surveyQuestion, answerOptions)
		}
	}, [answerOptions, index, currentIndex, handleSurveySubmissions, surveyQuestion])

	// Handle disabling next button based on selection requirements
	useEffect(() => {
		if (index !== currentIndex) return

		const selectedOptions = answerOptions?.filter(({ active }) => active)
		const minRequired = surveyQuestion?.displayOptions?.minNumSelected

		// If question has a minimum selection requirement
		if (minRequired) {
			setNextButtonDisabled(selectedOptions.length < minRequired)
			return
		}

		// If required but nothing selected
		if (isRequired && selectedOptions.length === 0) {
			setNextButtonDisabled(true)
			return
		}

		// Default to enabled
		setNextButtonDisabled(false)
	}, [answerOptions, surveyQuestion, index, currentIndex, setNextButtonDisabled, isRequired])

	return (
		<Transition
			show={index === currentIndex && animateInReady}
			as={Fragment}
			afterLeave={() => setAnimateInReady(true)}
			enter="transition ease-in-out duration-[400ms] delay-[400ms]"
			enterFrom={`opacity-0 ${
				translateDirection === 'forwards' ? 'translate-x-1/3' : '-translate-x-1/3'
			}`}
			enterTo="opacity-100 translate-x-0"
			leave="transition ease-in-out duration-[400ms]"
			leaveFrom="opacity-100 translate-x-0"
			leaveTo={`opacity-0 ${
				translateDirection === 'forwards' ? '-translate-x-1/3' : 'translate-x-1/3'
			}`}
		>
			<div className="flex flex-col">
				{/* Survey question body */}
				{surveyQuestion?.body && (
					<h3 className="text-lg font-medium text-center md:text-xl">{surveyQuestion.body}</h3>
				)}

				{/* Modal description */}
				{surveyQuestion?.displayOptions?.minNumSelected && (
					<p className="mt-4 font-medium text-center md:mt-16 text-gray-500 max-md:text-sm">
						Choose at least {surveyQuestion?.displayOptions?.minNumSelected}
					</p>
				)}

				{Array.isArray(surveyQuestion?.surveyAnswerOptions) && (
					<ul className="max-w-[800px] flex flex-wrap justify-center self-center sm:justify-items-center mt-8 md:mt-12">
						{answerOptions?.map(({ optionLabel, optionValue, active, id }) =>
							optionLabel ? (
								<li className="m-2 md:m-3 max-sm:w-full" key={id}>
									<button
										type="button"
										className={`px-4 py-3 md:py-2 font-medium border rounded-sm transition-colors duration-200 ease-in-out max-md:text-xs w-full ${
											active
												? 'border-bbx-spark bg-bbx-pearl-light text-bbx-spark'
												: 'border-gray-200 bg-gray-100 text-gray-500 font-medium'
										}`}
										onClick={() => handleValueChange(optionValue)}
									>
										{optionLabel}
									</button>
								</li>
							) : null
						)}
					</ul>
				)}
			</div>
		</Transition>
	)
}
