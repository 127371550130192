import { Fragment, useEffect, useRef } from 'react'
import type { ReactNode } from 'react'

import { Transition } from '@headlessui/react'
import { useClickAway } from 'react-use'

type ModalProps = {
	children: ReactNode
	isOpen: boolean
	closeModal: () => void
	desktopStyle: 'full-screen' | 'floating'
	afterClose?: () => void
	closeModalText?: string | null
}

export default function Modal({
	isOpen,
	closeModal,
	children,
	desktopStyle,
	afterClose,
	closeModalText = null
}: ModalProps) {
	const modalRef = useRef<HTMLDivElement>(null)

	// Close modal when clicked outside
	useClickAway(modalRef, (event) => {
		const onwardModal = document.getElementById('onward-modal')
		if (!onwardModal) {
			closeModal()
		}
	})

	// Keyboard event listener to close modal
	useEffect(() => {
		const closeCurrentModal = (e: KeyboardEvent) => (e.key === 'Escape' ? closeModal() : null)

		if (isOpen) {
			window.addEventListener('keydown', closeCurrentModal)
			document.body.style.overflowY = 'hidden'
		}

		return () => {
			window.removeEventListener('keydown', closeCurrentModal)
			document.body.style.overflowY = 'visible'
		}
	}, [isOpen])

	return (
		<Transition show={isOpen}>
			<div
				className={`fixed inset-0 z-[100] ${
					desktopStyle === 'floating' ? 'flex items-center justify-center sm:px-8' : 'lg:top-6'
				}`}
			>
				<div ref={modalRef}>
					<Transition.Child
						as={Fragment}
						appear
						enter="transition ease-in-out duration-500"
						enterFrom={desktopStyle === 'floating' ? 'opacity-0' : 'translate-y-full'}
						enterTo={desktopStyle === 'floating' ? 'opacity-100' : 'translate-y-0'}
						leave="transition ease-in-out duration-500"
						leaveFrom={desktopStyle === 'floating' ? 'opacity-100' : 'translate-y-0'}
						leaveTo={desktopStyle === 'floating' ? 'opacity-0' : 'translate-y-full'}
						afterLeave={afterClose}
					>
						<div
							className={`flex flex-col overflow-y-auto bg-white rounded  ${
								desktopStyle === 'floating'
									? 'fixed inset-0 sm:relative sm:max-h-[80vh] sm:min-w-[428px]'
									: 'relative h-screen'
							}`}
							role="dialog"
							aria-modal="true"
						>
							<button
								className="absolute p-1 text-gray-500 top-4 right-4 z-[110]"
								type="button"
								onClick={closeModal}
							>
								{!closeModalText ? <i className="w-3 fa-light fa-xmark" /> : closeModalText}
							</button>
							{children}
						</div>
					</Transition.Child>
				</div>
			</div>

			{/* Backdrop */}
			<Transition.Child
				as={Fragment}
				appear
				enter="transition ease-in-out duration-500"
				enterFrom="opacity-0"
				enterTo="opacity-100"
				leave="transition ease-in-out duration-500"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
			>
				<div className="fixed inset-0 z-[90] bg-bbx-style/50" aria-hidden="true" />
			</Transition.Child>
		</Transition>
	)
}
