import Link from 'next/link'

import useCanPurchaseSubscriptionBox from '@context/subscription-box/useCanPurchaseSubscriptionBox'

import CartTextNodes from '@data/i18n/cart.json'

import { MULTIPLE_BOX_ERROR_TEXT, lang } from '@lib/constants'
import useGetCheckoutUrl from '@lib/hooks/useGetCheckoutUrl'

export default function CheckoutButton({
	toggleModal,
	surveyComplete,
	text = CartTextNodes.checkoutCTA,
	className = ''
}: {
	toggleModal?: (state: boolean) => void
	surveyComplete?: boolean
	text?: string
	className?: string
}) {
	const checkOutUrl = useGetCheckoutUrl()
	const { subscriptionBoxInCart, alreadyPurchasedSubscriptionBox, canPurchaseSubscriptionBox } =
		useCanPurchaseSubscriptionBox()

	const isSubscriptionBoxInCart = Boolean(subscriptionBoxInCart)

	const disabledButton = (
		disabledText = CartTextNodes.checkoutCTA,
		explanation: string | null = null
	) => (
		<>
			<button type="button" disabled className="mt-6 button button-primary">
				{disabledText}
			</button>
			{explanation && <p className="mt-2 text-sm text-gray-600">{explanation}</p>}
		</>
	)

	// Show a disabled button
	if (!canPurchaseSubscriptionBox && isSubscriptionBoxInCart && !surveyComplete) {
		if (alreadyPurchasedSubscriptionBox) {
			// Disabled button
			return disabledButton(CartTextNodes.checkoutCTA, MULTIPLE_BOX_ERROR_TEXT)
		}

		// This scenario should never happened — but just in case, we'll give it a disabled button
		return disabledButton()
	}

	// Classes for the buttons
	const classes = `button button-primary ${className}`

	if (isSubscriptionBoxInCart && !surveyComplete) {
		// Open modal
		return (
			<button
				type="button"
				className={`mt-6 ${classes}`}
				onClick={() => {
					toggleModal?.(true)
				}}
			>
				{text}
			</button>
		)
	}

	const handleCheckoutWithInsurance = async () => {
		if (window.onwardApp && window.onwardClient) {
			console.log('[CheckoutButton] handleCheckoutWithInsurance')
			await window.onwardApp.updateCartForCheckoutWithInsurance()
		}

		const url = lang === 'es-es' ? '/come-back-soon' : checkOutUrl
		window.open(url, '_self')
	}

	const handleCheckoutWithoutInsurance = async () => {
		if (window.onwardApp && window.onwardClient) {
			console.log('[CheckoutButton] handleCheckoutWithoutInsurance')
			await window.onwardApp.updateCartForCheckoutWithoutInsurance()
		}

		const url = lang === 'es-es' ? '/come-back-soon' : checkOutUrl
		window.open(url, '_self')
	}
	// Else go to shopify checkout
	return (
		<div className="flex flex-col mt-6 gap-y-4">
			{lang === 'en-us' && <div id="onward-container" />}
			<Link legacyBehavior prefetch={false} href="/">
				{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
				<a type="submit" className={classes} onClick={() => handleCheckoutWithInsurance()}>
					{text}
				</a>
			</Link>
			{lang === 'en-us' && (
				<div className="flex justify-center">
					<Link legacyBehavior prefetch={false} href="/">
						{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
						<a
							type="submit"
							className="underline text-sm"
							onClick={() => handleCheckoutWithoutInsurance()}
						>
							{CartTextNodes.checkoutCTAWithoutInsurance}
						</a>
					</Link>
				</div>
			)}
		</div>
	)
}
