/* eslint-disable object-shorthand */

/* eslint-disable func-names */
// Cart
import { useEffect, useMemo, useRef, useState, useCallback } from 'react'

import { useSession } from 'next-auth/react'
import { useRouter } from 'next/router'

import useCanPurchaseSubscriptionBox from '@context/subscription-box/useCanPurchaseSubscriptionBox'
import { Survey } from '@context/subscription-box/useSurvey'
import { useClickAway } from 'react-use'

import CartTextNodes from '@data/i18n/cart.json'
import CommonTextNodes from '@data/i18n/common.json'

import useAddItems from '@lib/cart/use-add-items'
import useCart from '@context/useCartContext'
import useUpdateItems from '@lib/cart/use-update-items'
import {
	FREE_GROUND_SHIPPING_MINIMUM_AMOUNT,
	FREE_TWO_DAY_SHIPPING_MINIMUM_AMOUNT,
	lang
} from '@lib/constants'
import discountTiers, { productDiscountTierExcludeTag } from '@lib/discount/discount-tiers'

import Price from '@components/product/ProductView/Price'
import SurveyAuthModal from '@components/subscription-box/modals/SurveyAuthModal'

import CartDiscoundCodeField from './CartDiscoundCodeField'
import CartItem from './CartItem'
import CheckoutButton from './CheckoutButton'
import { ShopifyWebStorefrontClient } from './OnwardStorefrontClient'

export default function Cart() {
	const cartRef = useRef<HTMLDivElement>(null)
	const { data: session } = useSession()
	const { subscriptionBoxInCart } = useCanPurchaseSubscriptionBox()

	const isSubscriptionBoxInCart = Boolean(subscriptionBoxInCart)

	// Cart state
	const { cart, items, loading, appliedDiscountCodes, getCart, closeCart, openCart, isCartOpen } =
		useCart()

	const addItems = useAddItems()
	const updateItems = useUpdateItems()

	// Survey auth modal open state
	const [isModalOpen, toggleModal] = useState(false)

	// Router
	const router = useRouter()

	// Open cart if query params cart=open
	useEffect(() => {
		if (router.query.cart === 'open') {
			openCart()
		}
	}, [router, openCart])

	// Close on click outside
	useClickAway(cartRef, (event) => {
		const onwardModal = document.getElementById('onward-modal')
		if (!isModalOpen && !onwardModal) {
			closeCart()
		}
	})

	// Handle close cart on route change (when a product link in cart is clicked)
	useEffect(() => {
		const handleRouteChange = () => {
			closeCart()
		}

		const handleEscape = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				closeCart()
			}
		}

		router.events.on('routeChangeStart', handleRouteChange)
		document.addEventListener('keydown', handleEscape)

		return () => {
			router.events.off('routeChangeStart', handleRouteChange)
			document.removeEventListener('keydown', handleEscape)
		}
	}, [router.events, closeCart])

	const loyaltyTierDiscountMessage = useMemo(() => {
		if (
			lang === 'en-us' ||
			!session ||
			!session.user.customer ||
			!session.user.customer.tags ||
			session.user.customer.tags.length === 0
		) {
			return undefined
		}

		const { tags } = session.user.customer

		return discountTiers[lang].find(({ tag }) => tags.indexOf(tag) > -1)?.message ?? undefined
	}, [session])

	const hideLoyaltyTierDiscountMessage = useMemo(
		() =>
			!loyaltyTierDiscountMessage ||
			!items?.find(
				(item) => !item.merchandise.product.tags.includes(productDiscountTierExcludeTag)
			),
		[items, loyaltyTierDiscountMessage]
	)

	const initOnward = (cartItems: typeof items, force = false) => {
		if (force) window.onwardApp = null
		if (lang === 'en-us' && (!window.onwardApp || !window.onwardClient)) {
			const storefrontClient = new ShopifyWebStorefrontClient(getCart, addItems, updateItems)
			console.log('[Onward] initializeOnward - force: ', force)
			window.initializeOnward({
				storefrontClient,
				containerSelector: '#onward-container',
				force: true,
				locale: {
					currency_iso_code: 'USD',
					request_locale: 'en-US'
				}
			})
		} else if (!cartItems?.length) {
			window.onwardApp = null
			window.onwardClient = null
		}
	}

	// Check if the bbSurveySubmitted cookie exists
	useEffect(() => {
		const hasSubmittedSurvey = document.cookie
			.split(';')
			.some((cookie) => cookie.trim().startsWith('bbSurveySubmitted='))
		if (hasSubmittedSurvey) {
			// If the cookie exists, hide the modal
			toggleModal(false)
		}
	}, [])

	// Onward initialization
	useEffect(() => {
		initOnward(items, false)
	}, [items])

	// Onward initialization
	useEffect(() => {
		initOnward(items, true)
	}, [isCartOpen])

	// Handle back/forward cache for Onward
	useEffect(() => {
		const initOnwardEvent = (e: PageTransitionEvent) => {
			console.log('[initOnwardEvent] pageshow persisted:', e.persisted)
			if (e.persisted) {
				console.log('[initOnwardEvent] cache reload page')
				initOnward([], true)
			}
		}
		window.addEventListener('pageshow', initOnwardEvent)
		return () => {
			window.removeEventListener('pageshow', initOnwardEvent)
		}
	}, [])

	if (!isCartOpen) {
		return null
	}

	return (
		<>
			{/* Bg overlay */}
			<div className="modal-bg z-[60] is-open" />

			{/* Cart slideout */}
			<div
				className="cart-slideout flex flex-col z-[70] is-open max-md:overflow-scroll"
				ref={cartRef}
			>
				{/* Header */}
				<header className="relative px-12 pt-12 pb-4 text-center">
					{/* Close cart */}
					<button
						type="button"
						className="absolute top-0 left-0 px-6 py-4 text-lg"
						aria-label="Close"
						onClick={closeCart}
					>
						<i className="fa-light fa-xmark" />
					</button>

					{/* Title */}
					{Array.isArray(items) && items?.length > 0 ? (
						<>
							<h2 className="mb-4 text-4xl title">{CartTextNodes.title}</h2>
							<p className="text-sm font-medium text-gray-500">
								{`${CartTextNodes.shipping} ${CommonTextNodes.currency.symbol}${FREE_GROUND_SHIPPING_MINIMUM_AMOUNT.amount}+`}
							</p>
						</>
					) : (
						<>
							<h2 className="mb-4 text-4xl title">{CartTextNodes.greeting}</h2>
							<p className="text-sm font-medium text-bbx-gray12">{CartTextNodes.empty}</p>
						</>
					)}
				</header>

				{/* Cart contents */}
				{Array.isArray(items) && items?.length > 0 ? (
					// Show contents of cart if items are there
					<ul className="flex-1 md:h-full px-12 pt-1 pb-4 md:overflow-auto">
						{items?.map(({ quantity, merchandise, id, sellingPlanAllocation, estimatedCost }) => (
							<li key={id} className="flex py-6">
								{/* Cart Item */}
								<CartItem
									id={id}
									title={merchandise.product.title}
									variant={merchandise}
									quantity={quantity}
									sellingPlan={sellingPlanAllocation?.sellingPlan}
									estimatedCost={estimatedCost}
								/>
							</li>
						))}
					</ul>
				) : (
					// Otherwise show friendly message
					<div className="flex flex-col items-center h-full px-12 space-y-4 justify-stretch">
						<button
							type="button"
							aria-label="Close"
							className="button button-primary"
							onClick={closeCart}
						>
							{CartTextNodes.continue}
						</button>
						<p>
							{CartTextNodes.shipping} <Price price={FREE_GROUND_SHIPPING_MINIMUM_AMOUNT} />+
						</p>
					</div>
				)}

				{Array.isArray(items) && items?.length > 0 && (
					<footer className={`px-12 py-8 relative ${loading && 'opacity-70 pointer-events-none'}`}>
						{/* Discount code field */}
						<CartDiscoundCodeField />
						{/* Subtotal */}
						{cart?.estimatedCost?.subtotalAmount.amount && (
							<div className="flex items-center justify-between">
								<div className="text-sm text-gray-600">{CartTextNodes.subtotal}</div>
								<div className="text-sm font-medium text-gray-900">
									{CommonTextNodes.currency.symbol}
									{cart?.estimatedCost.subtotalAmount.amount}
								</div>
							</div>
						)}

						{/* Discount code(s) */}
						{appliedDiscountCodes.length > 0 && (
							<div className="flex items-center justify-between pt-4 text-sm border-t border-gray-200">
								<div className="text-gray-600">
									<span className="pr-4">
										{appliedDiscountCodes.length === 1
											? CartTextNodes.discountCode
											: CartTextNodes.discountCodes}
									</span>
								</div>
								<div
									className="flex flex-wrap font-medium text-gray-600"
									style={{ maxWidth: '64%' }}
								>
									{appliedDiscountCodes.map((code, index) => (
										<span key={code} className="mr-2">{`${code}${
											index + 1 < appliedDiscountCodes.length ? ', ' : ''
										}`}</span>
									))}
								</div>
								<div className="flex-grow pl-4 font-medium text-gray-900 text-right">
									-{CommonTextNodes.currency.symbol}
									{items
										.reduce(
											(amount, item) =>
												amount +
												item.discountAllocations.reduce(
													(sum, discountAllocation) =>
														sum + (Number(discountAllocation.discountedAmount?.amount) || 0),
													0
												),
											0
										)
										.toFixed(2)}
								</div>
							</div>
						)}

						{/* Loyalty tier discount */}
						{!hideLoyaltyTierDiscountMessage && (
							<div className="flex items-center justify-between pt-4 border-t border-gray-200">
								<div className="flex text-sm text-gray-600">
									<span>{loyaltyTierDiscountMessage}</span>
								</div>
								<div className="text-sm text-right text-gray-900">
									{CartTextNodes.calculatedAtNextStep}
								</div>
							</div>
						)}

						{/* Taxes */}
						{cart?.estimatedCost?.totalTaxAmount?.amount && (
							<div className="flex items-center justify-between pt-4 border-t border-gray-200">
								<div className="flex text-sm text-gray-600">
									<span>{CartTextNodes.tax}</span>
								</div>
								<div className="text-sm font-medium text-gray-900">
									{CommonTextNodes.currency.symbol}
									{cart?.estimatedCost.totalTaxAmount.amount}
								</div>
							</div>
						)}

						{/* Show free shipping if >150 USD */}
						{Number(cart?.estimatedCost.subtotalAmount.amount) >
							Number(FREE_GROUND_SHIPPING_MINIMUM_AMOUNT.amount) &&
							cart?.estimatedCost.subtotalAmount.currencyCode === 'USD' && (
								<div className="flex items-center justify-between pt-4 border-t border-gray-200">
									<div className="flex text-sm text-gray-600">
										<span>
											{cart?.estimatedCost.subtotalAmount.amount >
											Number(FREE_TWO_DAY_SHIPPING_MINIMUM_AMOUNT.amount)
												? '2 Day'
												: 'Ground'}{' '}
											Shipping
										</span>
									</div>
									<div className="text-sm font-medium text-gray-900">
										{CommonTextNodes.currency.symbol}0.00
									</div>
								</div>
							)}

						{/* Order total */}
						{cart?.estimatedCost?.totalAmount?.amount && (
							<div className="flex items-center justify-between pt-4 font-medium text-gray-900 border-t border-gray-200">
								<span>{CartTextNodes.total}</span>
								<span>
									{CommonTextNodes.currency.symbol}
									{cart?.estimatedCost.totalAmount?.amount}
								</span>
							</div>
						)}

						{/* Render CheckoutButton based on cookie existence */}
						{document.cookie
							.split(';')
							.some((cookie) => cookie.trim().startsWith('bbSurveySubmitted=')) ? (
							<CheckoutButton surveyComplete className="w-full" />
						) : (
							<CheckoutButton toggleModal={toggleModal} className="w-full" />
						)}
					</footer>
				)}
			</div>

			{/* TODO: Possibly refactor? */}
			{/* Survey auth modal */}
			{isSubscriptionBoxInCart && (
				<Survey surveyType="new">
					<SurveyAuthModal
						isModalOpen={isModalOpen}
						closeModal={() => {
							// Remove the `subscribe` from the URL params
							toggleModal(false)
						}}
					/>
				</Survey>
			)}
		</>
	)
}
