import useToast from '@context/useToast'

import type { CartItem } from '@lib/types/cart'

import useCart, { formatCart } from '@context/useCartContext'
import type { UpdateItemParams } from './update-item'
import updateItem from './update-item'

/**
 * A hook to update cart items and revalidate cart's data
 */
export default function useUpdateItem() {
	const { revalidate } = useCart()
	const toast = useToast()

	const updateItemAndRevalidateCart = async ({
		id,
		variantId,
		quantity,
		sellingPlanId,
		quantityAvailable
	}: UpdateItemParams) => {
		type CurrentValueType = Pick<ReturnType<typeof useCart>, 'cart' | 'items' | 'itemCount'>

		if (quantityAvailable && quantity > quantityAvailable) {
			toast("Can't add to cart", 'Not enough items in stock')
			return
		}

		await revalidate(
			async (currentValue?: CurrentValueType) => {
				if (!currentValue) {
					return { cart: null, items: null, itemCount: 0 }
				}

				const cartData = await updateItem({ id, variantId, quantity, sellingPlanId })

				const newCartData = formatCart(cartData)

				return newCartData
			},
			{
				rollbackOnError: true,
				revalidate: quantity <= 0, // don't revalidate, because the updater function returns the new cart.
				optimisticData: (currentData) => {
					const newItems =
						currentData?.items
							?.map((item) => {
								if (item.id === id) {
									return {
										// Everything previous in the item
										...item,
										// New fields that could have changed
										variantId,
										quantity,
										sellingPlanId,
										// Calculate the new cost
										estimatedCost: {
											subtotalAmount: {
												...item.estimatedCost.subtotalAmount,
												// Format amount to 2 decimal places
												amount: (
													Number(item.estimatedCost.subtotalAmount.amount) *
													(quantity / item.quantity)
												).toFixed(2)
											}
										}
									}
								}

								return item
							})
							.filter((item) => item.quantity > 0) || []
					return currentData
						? {
								...currentData,
								itemCount: newItems.reduce(
									(acc: number, curr: CartItem) => (curr.quantity ? acc + curr.quantity : acc),
									0
								),
								items: newItems
							}
						: { cart: null, items: [], itemCount: 0 }
				}
			}
		)
	}

	return updateItemAndRevalidateCart
}
