import React, { useRef, useEffect, useContext } from 'react'

import NavHeightContext from '@context/NavHeightContext'

import { useSession } from 'next-auth/react'

import menuData from '@data/menu.json'

import Cart from '@components/cart/Cart'

import DesktopNav from './DesktopNav'
import MobileNav from './MobileNav'
import PromoBar from './PromoBar'

export default function Nav() {
	const { data: session } = useSession()
	const navWrapperRef = useRef<HTMLDivElement>(null)
	const navHeightContext = useContext(NavHeightContext)

	if (!navHeightContext) {
		throw new Error('Nav must be used within a NavHeightContext.Provider')
	}

	const { setNavHeight } = navHeightContext

	useEffect(() => {
		// Create a new ResizeObserver that will be called whenever the size of an observed element changes.
		const resizeObserver = new ResizeObserver((entries) => {
			// Loop through all observed elements that had their size changed.
			for (const entry of entries) {
				// If the navWrapperRef is currently referencing an element (i.e., it's not null),
				// update the navHeight state with the current height of the element.
				if (navWrapperRef.current) {
					setNavHeight(navWrapperRef.current.offsetHeight)
				}
			}
		})

		// If the navWrapperRef is currently referencing an element, start observing that element for size changes.
		if (navWrapperRef.current) {
			resizeObserver.observe(navWrapperRef.current)
		}

		// Return a cleanup function that will be called when the component unmounts or before the effect runs again.
		// This function stops observing the element for size changes.
		return () => {
			if (navWrapperRef.current) {
				resizeObserver.unobserve(navWrapperRef.current)
			}
		}
	}, [])

	return (
		<div className="fixed inset-x-0 top-0 z-30" ref={navWrapperRef}>
			<header className="relative bg-white border-b border-gray-200">
				{/* Global Promo Bar Message */}
				<PromoBar />

				{/* Mobile */}
				<MobileNav menuData={menuData} isLoggedIn={!!(session as any)?.shopifyAccessToken} />

				{/* Desktop */}
				<DesktopNav
					menuData={menuData}
					navWrapperRef={navWrapperRef}
					isLoggedIn={!!(session as any)?.shopifyAccessToken}
				/>
			</header>

			{/* Cart */}
			<Cart />
		</div>
	)
}
