import React, { useMemo, useState, createContext, useContext, useCallback } from 'react'
import useSWR, { type KeyedMutator } from 'swr'

import destroyCart from '@lib/cart/destroy-cart'
import getCartId from '@lib/cart/get-cart-id'
import shouldUseDiscount from '@lib/discount/should-use-discount'
import fetcher from '@lib/fetcher'
import cartDiscountCodesUpdateMutation from '@lib/mutations/cart-discount-codes-update'
import getCartQuery from '@lib/queries/get-cart-query'
import type { UpdateCartAttributesInput } from '@lib/queries/update-cart-attributes'
import updateCartAttributes from '@lib/queries/update-cart-attributes'
import type { CartErrorCode, CartItem, Cart as CartType } from '@lib/types/cart'
import { normalizeCart, normalizeCartItem } from '@lib/utils/normalizers'

import addItem from '@lib/cart/add-item'

export type GetCartReturn = {
	cart: CartType | null | undefined
	items: CartItem[] | null | undefined
	itemCount: number
}

type GetCartDiscountCodesUpdateReturn = {
	cart: CartType
	userErrors: { code: CartErrorCode; field: string[]; message: string }[]
}

type CartContextType = {
	cart: CartType | null | undefined
	items: CartItem[] | null | undefined
	itemCount: number
	loading: boolean
	revalidate: KeyedMutator<GetCartReturn>
	appliedDiscountCodes: string[]
	addDiscountCode: (code: string) => Promise<{ applied: boolean; errors: any | null }>
	removeDiscountCode: (code: string) => Promise<void>
	getCart: () => Promise<GetCartReturn>
	addSurveyAnswerToCartAttributes: (
		surveyAnswers: { question_id: string; answer: string }[]
	) => Promise<null>
	isCartOpen: boolean
	openCart: () => void
	closeCart: () => void
	toggleCart: () => void
}

const CartContext = createContext<CartContextType | undefined>(undefined)

export function CartProvider({ children }: { children: React.ReactNode }) {
	const { data, isValidating: loading, error, mutate } = useSWR<GetCartReturn>('cart', getCart)
	const [isCartOpen, setIsCartOpen] = useState(false)

	const { cart, items } = data ?? {}
	const itemCount = data?.itemCount ?? 0

	const appliedDiscountCodes = useMemo(
		() =>
			data?.cart?.discountCodes && data.cart.discountCodes.length > 0
				? data.cart.discountCodes.filter(({ applicable }) => applicable).map(({ code }) => code)
				: [],
		[data]
	)

	const openCart = useCallback(() => {
		setIsCartOpen(true)
	}, [])

	const closeCart = useCallback(() => {
		setIsCartOpen(false)
	}, [])

	const toggleCart = useCallback(() => {
		setIsCartOpen(!isCartOpen)
	}, [isCartOpen])

	async function getCart(): Promise<GetCartReturn> {
		const cartId = getCartId()

		if (cartId) {
			const cartData = await fetcher({
				query: getCartQuery,
				variables: { cartId }
			})

			return formatCart(cartData)
		}

		return { cart: null, items: null, itemCount: 0 }
	}

	async function addSurveyAnswerToCartAttributes(
		surveyAnswers: {
			question_id: string
			answer: string
		}[]
	) {
		if (cart && items && items.length) {
			try {
				const updateCartAttributesObject: UpdateCartAttributesInput = {
					cartId: cart.id,
					attributes: surveyAnswers.map((sa) => ({
						// add 'survey_' in the beginning of the key to differentiate survey answers from other attributes
						key: `survey_${sa.question_id}`,
						// '-' means blank answer
						value: sa.answer || '-'
					}))
				}

				await fetcher({
					query: updateCartAttributes,
					variables: updateCartAttributesObject
				})
			} catch (err: any) {
				console.error('Unable to add Survey Answer to Shopify cart attributes: ', err)
			}
		}

		return null
	}

	async function addDiscountCode(addCode: string) {
		let applied = false
		let errors = null

		await mutate(
			async (currentValue) => {
				if (!currentValue) {
					return { cart: null, items: null, itemCount: 0 }
				}

				const freeVariants = await shouldUseDiscount({
					productsInCart: cart?.lines || [],
					code: addCode
				})

				if (freeVariants) {
					// We'll assume they want us to add the first product to the cart; there's no really good solution
					// Accept for a product selector, though that's a bit overkill for this

					// Variants sorted from cheapest to most expensive
					const sortedVariants = freeVariants.sort(
						(a: any, b: any) => parseFloat(a.price) - parseFloat(b.price)
					)

					// Basically find the first variant that's not $0
					// In the future, we might consider accounting for variants that are in stock
					const variantToAdd = sortedVariants.find(
						(variant: any) => parseFloat(variant.price) !== 0
					)

					const alreadyInCart = currentValue.items?.some(
						(line: any) => line.merchandise.id === variantToAdd?.id
					)

					if (variantToAdd && !alreadyInCart) {
						await addItem({
							variantId: variantToAdd.id,
							quantity: 1
						})
					}
				}

				const { cart: updatedCart, userErrors } = await updateCartDiscountCodes([
					...appliedDiscountCodes,
					addCode
				])

				if (userErrors.length > 0) {
					errors = userErrors
				}

				const codeData = updatedCart.discountCodes.find(
					(discountCode) => discountCode.applicable && discountCode.code === addCode
				)
				applied = Boolean(codeData)

				return formatCart({ cart: updatedCart })
			},
			{
				rollbackOnError: true
			}
		)

		if (errors) {
			console.error(errors)
		}

		return { applied, errors } as {
			applied: boolean
			errors: GetCartDiscountCodesUpdateReturn['userErrors'] | null
		}
	}

	async function removeDiscountCode(removeCode: string) {
		await mutate(
			async (currentValue) => {
				if (!currentValue) {
					return { cart: null, items: null, itemCount: 0 }
				}

				const { cart: updatedCart, userErrors } = await updateCartDiscountCodes(
					appliedDiscountCodes.filter((code) => code !== removeCode)
				)

				if (userErrors.length > 0) {
					console.error(userErrors)
				}

				return formatCart({ cart: updatedCart })
			},
			{
				rollbackOnError: true
			}
		)
	}

	const value = useMemo(
		() => ({
			cart,
			items,
			itemCount,
			loading,
			revalidate: mutate,
			appliedDiscountCodes,
			addDiscountCode,
			removeDiscountCode,
			getCart,
			addSurveyAnswerToCartAttributes,
			isCartOpen,
			openCart,
			closeCart,
			toggleCart
		}),
		[
			cart,
			items,
			itemCount,
			loading,
			mutate,
			appliedDiscountCodes,
			addDiscountCode,
			removeDiscountCode,
			getCart,
			addSurveyAnswerToCartAttributes,
			isCartOpen,
			openCart,
			closeCart,
			toggleCart
		]
	)

	return <CartContext.Provider value={value}>{children}</CartContext.Provider>
}

export default function useCart() {
	const context = useContext(CartContext)
	if (context === undefined) {
		throw new Error('useCart must be used within a CartProvider')
	}
	return context
}

export const formatCart = async (cartData: any): Promise<GetCartReturn> => {
	if (cartData?.cart) {
		const cartProducts = cartData.cart.lines.edges

		const normalizedProducts = cartProducts.map((product: any) => normalizeCartItem(product))

		const normalizedCart = normalizeCart(cartData.cart)

		// Total number of items in cart
		const newItemCount = normalizedProducts.reduce(
			(acc: number, curr: CartItem) => (curr.quantity ? acc + curr.quantity : acc),
			0
		)

		return { cart: normalizedCart, items: normalizedProducts, itemCount: newItemCount }
	}

	// If the cart does not exist, destroy it
	// https://community.shopify.com/c/storefront-api-and-sdks/storefront-api-cart-completedat-property/td-p/1399234
	// The cart will be null if it does not exist
	await destroyCart()

	return { cart: null, items: null, itemCount: 0 }
}

async function updateCartDiscountCodes(discountCodes: string[]) {
	const cartId = getCartId()

	const responseData: { cartDiscountCodesUpdate: GetCartDiscountCodesUpdateReturn } = await fetcher(
		{
			query: cartDiscountCodesUpdateMutation,
			variables: { cartId, discountCodes }
		}
	)

	return responseData.cartDiscountCodesUpdate
}
